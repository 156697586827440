import ReactGA from "react-ga4";
import {
	osName,
	isBrowser,
	isMobile,
	browserName,
	mobileVendor,
	mobileModel,
} from "react-device-detect";

export const logGAScreen = async (location) => {
	let routeName = "";
	if (location.pathname === "/") {
		routeName = "web_visit";
	} else {
		if (location.pathname.includes("/login")) {
			if (location.pathname.includes("/login/otp-validation")) {
				routeName = "web_login_otp_modal";
			} else if (location.pathname.includes("/login/hyperadmin-otp-validation")) {
				routeName = "web_hyperadmin_otp_modal";
			} else if (location.pathname.includes("/login/hyperadmin-user-selection")) {
				routeName = "web_hyperadmin_user_selection";
			} else if (location.pathname.includes("/login/reset-password")) {
				routeName = "web_forget_password";
			} else if (location.pathname.includes("/login/reset-password/otp-validation")) {
				routeName = "web_register_otp_modal";
			} else if (location.pathname.includes("/login/first-time-reset-password")) {
				routeName = "web_firsttime_reset_password";
			} else {
				routeName = "web_sign_in";
			}
		} else if (location.pathname.includes("/register")) {
			routeName = "web_register";
		} else if (location.pathname.includes("/course")) {
			if (location.pathname.includes("/course/course-details")) {
				routeName = "web_upcoming_course_detail";
			} else if (location.pathname.includes("/course/reschedule")) {
				routeName = "web_reschedule_course";
			} else if (location.pathname.includes("/course/withdrawal")) {
				routeName = "web_withdraw_course";
			} else if (location.pathname.includes("/course/course-info")) {
				routeName = "web_course_detail";
			} else if (location.pathname.includes("/course/shuttle-bus")) {
				routeName = "web_shuttle_bus_selection";
			} else if (location.pathname.includes("/course/bundle")) {
				routeName = "web_bundle_details";
			} else if (location.pathname.includes("/course/bundle-list")) {
				routeName = "web_completed_bundle_cert";
			} else if (location.pathname.includes("/course/download-resources")) {
				routeName = "web_download_resources";
			} else if (location.pathname.includes("/course/resource-details")) {
				routeName = "web_resources_details";
			} else if (location.pathname.includes("/course/register")) {
				routeName = "web_course_reg_form";
			} else if (location.pathname.includes("/course/payment")) {
				routeName = "web_course_reg_paymentmethod";
			} else if (location.pathname.includes("/course/endorse")) {
				routeName = "web_course_reg_termcondition";
			} else if (location.pathname.includes("/course/confirm")) {
				routeName = "web_course_reg_confirmation";
			} else if (location.pathname.includes("/course/success")) {
				routeName = "web_course_reg_success";
			} else if (location.pathname.includes("/course/meal-selection")) {
				routeName = "web_meal_selection";
			} else if (location.pathname.includes("/course/success-page")) {
				routeName = "web_reschedule_withdrawal_success";
			} else if (location.pathname.includes("/course/meal-success-page")) {
				routeName = "web_meal_selection_Success";
			} else if (location.pathname.includes("/course/shuttle-bus-success-page")) {
				routeName = "web_shuttle_bus_success";
			} else {
				routeName = "web_course_home";
			}
		} else if (location.pathname.includes("/contact-us")) {
			routeName = "web_course_contactus";
		} else if (location.pathname.includes("/news")) {
			if (location.pathname.includes("/news/article")) {
				routeName = "web_article_details";
			} else if (location.pathname.includes("/events")) {
				routeName = "web_event_details";
			} else if (location.pathname.includes("/news/teaser")) {
				routeName = "web_teaser_details";
			} else {
				routeName = "web_news_home";
			}
		} else if (location.pathname.includes("/notification")) {
			if (location.pathname.includes("/notification/article")) {
				routeName = "web_article_reply";
			} else if (location.pathname.includes("/notification/")) {
				routeName = "web_notifications_details";
			} else {
				routeName = "web_notification_home";
			}
		} else if (location.pathname.includes("/faq")) {
			routeName = "web_faq_home";
		} else if (location.pathname.includes("/profile")) {
			if (location.pathname.includes("/profile/edit")) {
				routeName = "web_update_profile";
			} else if (location.pathname.includes("/profile/change-password")) {
				routeName = "web_change_password";
			} else if (location.pathname.includes("/profile/change-password/otp-validation")) {
				routeName = "web_verify_email";
			} else if (location.pathname.includes("/profile/change-email")) {
				routeName = "web_change_email";
			} else if (location.pathname.includes("/profile/change-email/otp-validation")) {
				routeName = "web_change_email_verify";
			} else {
				routeName = "web_profile";
			}
		} else if (location.pathname.includes("/home")) {
			routeName = "web_home";
		}
	}
   
	ReactGA.send({ hitType: "pageview", page: location.pathname, title: routeName });
};

export const logGAEvent = async () => {
	console.log("submit event");

	ReactGA.event({
		category: "your category",
		action: "your action",
		label: "your label", // optional
		// value: 99, // optional, must be a number
		// nonInteraction: true, // optional, true/false
		// transport: "xhr", // optional, beacon/xhr/image
	});
};

export const logGAUser = async (id, name, dob, nationality, gender) => {
	ReactGA.set({ user_id: id });
	ReactGA.gtag("set", "user_properties", {
		userName: name,
		age: getAge(dob),
		nationality: nationality,
		gender: gender,
		osName: osName,
		browserName: browserName,
		mobileVendor: mobileVendor,
		mobileModel: mobileModel,
		isBrowser: isBrowser,
		isMobile: isMobile,
		type: "web",
	});
};

export const removeLogGAUser = async () => {
	ReactGA.set({ user_id: null });
	ReactGA.gtag("set", "user_properties", {
		userName: null,
		age: null,
		nationality: null,
		gender: null,
		osName: null,
		browserName: null,
		mobileVendor: null,
		mobileModel: null,
		isBrowser: null,
		isMobile: null,
		type: "web",
	});
};

const getAge = (dateString) => {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age.toString();
};

